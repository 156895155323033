// src/pages/practiceAreas.js
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHelmetSafety, faMoneyBillTrendUp, faBuilding, faSignHanging } from '@fortawesome/free-solid-svg-icons';

function PracticeAreas() {
  return (
    <div className="container mx-auto p-5 my-4">
      <div className="grid gap-12 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2">
        <div className="bg-base-200 text-black shadow-xl p-4">
          <div className="flex items-center mb-4">
            <FontAwesomeIcon icon={faHelmetSafety} className="text-3xl mr-2" />
            <h2 className="text-2xl font-semibold">Construction</h2>
          </div>
          <p className="text-lg">
            Feldman & Associates, Inc. provides counseling and legal expertise to clients at every stage of private and public construction projects. We represent general contractors, subcontractors, material suppliers, private owners, and design professionals, regarding all construction project issues. This includes, contractor license issues, contract documents, public bids and disputes, bid pre-qualification, labor compliance, change order requests, claim preparation and analysis, as well as mediation, arbitration and litigation. Our construction lawyers have extensive experience in most types of construction projects including schools, hospitals, luxury residential homes, condominiums, airports, water treatment plants, universities, courthouses, pipelines, and also commercial and retail buildings. Mark Feldman is acknowledged by his peers as a “super lawyer” in the area of construction law. We often receive referrals from law firms where clients rely on us to handle their most challenging construction disputes.
          </p>
        </div>

        <div className="bg-base-200 text-black shadow-xl p-4">
          <div className="flex items-center mb-4">
            <FontAwesomeIcon icon={faBuilding} className="text-3xl mr-2" />
            <h2 className="text-2xl font-semibold">Business</h2>
          </div>
          <p className="text-lg">
            Feldman & Associates, Inc. drafts and negotiates a wide range of commercial agreements such as letters of intent, consulting agreements, lease agreements, confidentiality agreements and other general commercial documents. We also assist companies with drafting construction and real estate contracts. These include owner/contractor and contractor/subcontractor agreements, and purchase and sale agreements for property. Our attorneys are seasoned negotiators. They provide early case analysis to prevent, and when appropriate, prosecute business disputes through mediation, arbitration, and litigation. On behalf of clients, we send demand letters, secure their lien rights, and collect monies owed.
          </p>
        </div>

        <div className="bg-base-200 text-black shadow-xl p-4">
          <div className="flex items-center mb-4">
            <FontAwesomeIcon icon={faMoneyBillTrendUp} className="text-3xl mr-2" />
            <h2 className="text-2xl font-semibold">Prevailing Wage</h2>
          </div>
          <p className="text-lg">
            Feldman & Associates, Inc. offers legal counseling and advice on prevailing wage, labor compliance, and reporting requirements on public works construction projects. We represent contractors and subcontractors in DLSE investigations and enforcement actions related to apprenticeship, prevailing wage, worker classification, and fringe benefits contributions.
          </p>
        </div>

        <div className="bg-base-200 text-black shadow-xl p-4">
          <div className="flex items-center mb-4">
            <FontAwesomeIcon icon={faSignHanging} className="text-3xl mr-2" />
            <h2 className="text-2xl font-semibold">Real Estate</h2>
          </div>
          <p className="text-lg">
            Feldman & Associates, Inc. represents property owners, purchasers, tenants, investors, lenders, and developers in a variety of real estate transactions. These include, acquiring, selling, financing, leasing, building, or renovating real estate projects. When you are considering a real estate transaction, consultation with a lawyer can help ensure that you understand the terms, and avoid costly mistakes. Whether it involves a real estate transaction or litigation, we work with you to get the best possible results in the most cost-effective manner.
          </p>
        </div>
      </div>
    </div>
  );
}

export default PracticeAreas;
