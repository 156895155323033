// src/pages/About.js
import React from 'react';

function About() {
  return (
    <div className="container mx-auto p-5">
      <section className="mb-10 text-black">
        <h2 className="text-3xl font-bold mb-3">Introduction</h2>
        <p>
          We know our clients need answers and recommendations quickly. Bid disputes arise fast, and claims tie up sorely needed capital, so we strive to provide all information and documents to our clients and get their input to craft the best case strategy possible. This allows clients to make informed decisions each step of the way.
        </p>
      </section>

      <section className="mb-10">
        <h2 className="text-3xl font-bold mb-3">Representative Clients</h2>
        <ul className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-1">
          {[
            "AAA Solar Electric",
            "A&B Construction",
            "ACC Contractors",
            "Access Pacific",
            "Air Plus",
            "Aital Custom Glass & Mirror",
            "Alfa 26 Construction",
            "All Weather Construction & Coating",
            "Angeles Contractor",
            "Aramexx Construction",
            "Accucon Construction",
            "AWI Builders",
            "BH Properties",
            "Blusky Restoration Contractors",
            "BRAD Management",
            "Bulldog Paving",
            "Cal-City Construction",
            "Chalmers Construction Services",
            "Coast to Coast Renovations",
            "Corner Construction",
            "Demand Plastering",
            "DLK Development",
            "Earthtek Builders",
            "El Prado Cleaning",
            "Ecocentrix Landscape Architecture",
            "Eco Construction",
            "Framing Specialists",
            "Fix Painting",
            "Frigid Engineering Services",
            "Genesis Dispatch",
            "H-Mart",
            "Har-Bro Incorporated",
            "Henderson Mechanical",
            "Hudson Pacific Properties",
            "Horizon General Contractor",
            "Horizons Construction Company",
            "Industrial High Voltage",
            "Interior Demolition",
            "Intertex General Contractors",
            "Joyce Inspections & Testing",
            "L&T Construction",
            "Liberty Maintenance",
            "Mackone Development",
            "MD Insulation",
            "Midwest Roofing",
            "Morillo Construction",
            "Nazerian Group",
            "New Creation Builders",
            "Newform Construction",
            "Nile Advanced Construction",
            "NR Development",
            "ODC Engineering & Technology",
            "Pacific Athletic Wear",
            "Parking Structure Builders",
            "Platinum Construction",
            "Pro West Constructors",
            "Pub Construction",
            "Rama Grading",
            "RedBuilt",
            "R & R Construction",
            "R&V Refrigeration & Air Conditioning",
            "Romm Construction",
            "SAS Services Group",
            "Sevan Plumbing",
            "Shenk Developers",
            "Sinanian Development",
            "So. Cal. Development",
            "Telenet Voip",
            "Tim Barber Ltd. Architecture",
            "Up to Code",
            "USS Cal Builders",
            "M. Winter Design",
            "West-Tech Mechanical",
          ].map((client, index) => (
            <li key={index}>
              {client}
            </li>
          ))}
        </ul>
      </section>

      <section className="mb-10 bg-gray-100 text-black shadow-xl p-8">
        <h2 className="text-3xl font-bold mb-3">Successful Client Outcomes</h2>
        <ul className="list-disc pl-5">
          {[
            "Obtained a $9.5 million dollar arbitration award against a public entity.",
            "Obtained a $4.53 million settlement from a public entity.",
            "Obtained a $3.3 million settlement from a public entity.",
            "Obtained a $3.4 million settlement from a public entity.",
            "Obtained a $1.7 million judgment against a drywall subcontractor.",
            "Obtained a $953,000 jury verdict against a public entity.",
            "Obtained a $846,433 arbitration award against a public entity.",
            "Obtained a $586,000 judgment against a concrete subcontractor.",
            "Obtained a $450,000 settlement from a public entity.",
            "Defeated a $25 million claim for construction injury and obtained $228,000 for the general contractor.",
            "Defeated a $7 million claim by a subcontractor who was unlicensed for a brief period during a construction project.",
            "Defeated a $489,000 claim by a subcontractor who was unlicensed for a brief period during a construction project.",
            "Defeated attempt by the Contractors State License Board to revoke a license.",
            "Converted a termination for cause into a termination for convenience, and obtained compensation for the general contractor.",
          ].map((outcome, index) => (
            <li key={index} className="mb-2">
              {outcome}
            </li>
          ))}
        </ul>
      </section>
    </div>
  );
}

export default About;
