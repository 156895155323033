import React from 'react';
import { useParams } from 'react-router-dom'; // Import useParams to get attorney ID from URL params

// Example attorney data (can be fetched dynamically or from a database)
const attorneysData = [
  {
    id: 1,
    name: 'Mark Feldman',
    title: 'Partner',
    phone: '(310) 312-5401',
    fax: '(310) 312-5409',
    email: 'mfeldman@feldmanandassoc.com',
    largeRatingBadge: '/attorney-images/1-badge1.png',
    smallRatingBadge: {
      image: '/attorney-images/1-badge2.png',
      title: 'AV Preeminent (2001–2021)',
      details: 'Highest Possible Rating in Both Legal Ability & Ethical Standards Reflecting the confidential opinions of members of the bar and Judiciary.',
    },
    practiceAreas: [
      'Construction Law',
      'Public Works',
      'Surety Law',
      'Prevailing Wage Litigation',
      'Real Estate',
      'Business Litigation',
    ],
    education: [
      { title: 'University Attended', name: 'University of California, Los Angeles, B.A., Economics-Business, Cum Laude, 1987' },
      { title: 'Law School Attended', name: 'University of Southern California, J.D., 1990' },
    ],
    biography: `Phi Alpha Delta. Legion Lex Scholar.

    Author: “Selecting an Entity for Acquiring and Holding Title to Property,” Real Estate Transactions, The Michie Company, 1993.

    Panel Member: Building Industry Credit Association Seminar, “General Contractors: Recognizing, Making & Defending Against Claims,” 1994;

    “Public Contracts and Procurement Regulations in California,” Lorman Education Services Seminar, 2003;

    “Fundamentals of Construction Law,” Sterling Education Services, Seminar, 2004;

    “California Mechanics Lien Law Remedies – Why and How,” Lorman Education Services Seminar, 2005;

    “California Mechanics’ Liens and Related Construction Remedies: Public and Private Projects,” Lorman Education Services Seminar, 2006.
    
    Year of First Admission:
      1991

    Admission:
      1991, California; 1991, U.S. District Court, Central, Northern, Southern and Eastern Districts of California and U.S. Court of Appeals, Ninth Circuit
    
    Memberships:
      Los Angeles County Bar Association; State Bar of California.

    Birth Place:
      Los Angeles, California
    `,
    membershipsAndAwards: ["AV Preeminent – Highest Possible Rating in Both Legal Ability & Ethical Standards Reflecting the confidential opinions of members of the bar and Judiciary. 2001–2021", "Super lawyer 2012 – 2021"],
    reportedCases: [
      'Mesa Forest Products, Inc. v. St. Paul Mercury Insurance Company (1999) 86 Cal. Rptr. 2d 398',
      'Begl Const. Co. vs. City of Los Angles (2003) WL 141480',
      'Pearl v. Deitch (2007) WL 778867',
      'USS Cal Builders v. Ennis (2011) WL 5138573',
      'Othman v. Zions First National Bank (2012) WL 1593233',
      'G. Voskanian Construction v. Alhambra Unified School Dist. (2012) 204 Cal. App. 4th 981',
      'Southern California Steel v. East West Bank (2012) WL 1571536'
    ],
  },
  {
    id: 2,
    name: 'Adam Meyers',
    title: 'Partner',
    phone: '(310) 312-5401',
    fax: '(310) 312-5409',
    email: 'ameyers@feldmanandassoc.com',
    largeRatingBadge: '/attorney-images/2-badge1.png',
    practiceAreas: [
      'Construction Law',
      'Public Works',
      'Labor and Employment',
      'Prevailing Wage Litigation',
      'Real Estate',
    ],
    education: [
      { title: 'University Attended', name: 'University of California, San Diego, B.A., Political Science, 2003; University of California, Irvine, Masters of Urban and Regional Planning, 2008' },
      { title: 'Law School Attended', name: 'Loyola Law School, J.D., 2011' },
    ],
    biography: `Production Editor, Loyola Law Review, 2010-2011. Judicial Extern to Hon. Margaret M. Morrow, U.S. District Court, Central District, 2010

  Jewish Federation Real Estate & Construction Division, Cabinet Member
  
  Year of First Admission:
  2011

  Admission:
  2011, California; 2012, U.S. District Court, Central District of California
  
  Memberships:
  State Bar of California.
  
  Birth Place:
  Los Angeles, California
  `,
    membershipsAndAwards: ["Super lawyer 2020 – 2021"],
  },
  {
    id: 3,
    name: 'Sumner Schwartz',
    title: 'Associate',
    phone: '(310) 312-5401',
    fax: '(310) 312-5409',
    email: 'sschwartz@feldmanandassoc.com',
    practiceAreas: [
      'Business Law',
      'Construction Law',
      'Public Works',
      'Surety Law',
      'Civil Litigation',
      'Real Estate Litigation',
      'Prevailing Wage Litigation',
      'Marijuana Law',
      'Crypto Law',
    ],
    education: [
      { title: 'University Attended', name: 'Occidental College, B.A., English, 2018' },
      { title: 'Law School Attended', name: 'University of California, Irvine School of Law, 2021' },
    ],
    biography: `Pole Vaulter

  Memberships:
  Entertainment and Sports Law Society, Irvine School of Law
  Business Law Society, Irvine School of Law
  Jewish Law Association, Irvine School of Law
  `,
    membershipsAndAwards: ["Deans list – Fall 2015, 2016 – 2018"],
  },
  {
    "id": 4,
    "name": "John Paul Cosico",
    "title": "Associate",
    "phone": "310-312-5432",
    "fax": "310-312-5409",
    "email": "jcosico@feldmanandassoc.com",
    "practiceAreas": [
      "Civil Litigation",
      "Construction Law",
      "Real Estate Law",
      "Public Works",
      "Surety Law"
    ],
    "education": [
      {
        "title": "Law School Attended",
        "name": "Loyola Marymount, J.D., 2021"
      },
      {
        "title": "University Attended",
        "name": "University of California, Santa Barbara, B.A. Anthropology, 2017"
      }
    ],
    "admission": "2021, California",
    "membershipsAndAwards": [
      "State Bar of California"
    ],
    "birthPlace": "Philippines"
  },
  {
    id: 5,
    name: 'Jessica P. Bravo',
    title: 'Of Counsel',
    practiceAreas: [
      'Construction Litigation'
    ],
    education: [
      { title: 'University Attended', name: 'University of Wisconsin, Madison, B.A., International Relations, 2006' },
      { title: 'Law School Attended', name: 'The John Marshall Law School, J.D., 2010' }
    ],
    yearOfFirstAdmission: '2011',
    admission: '2011, California',
    memberships: [
      'State Bar of California'
    ],
    birthPlace: 'Los Angeles, California'
  },
  {
    id: 6,
    name: 'Craig Lang',
    title: 'Of Counsel',
    phone: '(310) 312-5401',
    fax: '(310) 312-5409',
    email: 'clang@feldmanandassoc.com',
    education: [
      { title: 'University Attended', name: 'University of Kansas, B.S., 1997' },
      { title: 'Law School Attended', name: 'Loyola Law School, Los Angeles, J.D., 2002' },
      { title: 'Year of First Admission', name: '2002'}
    ],
    admission: '2002, U.S. District Court, Central District of California; 2002, California; 2003, Kansas; 2003, U.S. District Court, District of Kansas; 2004, U.S. District Court, Western District of Missouri; 2004, Missouri',
  },
  {
    id: 7,
    name: 'Andrew Monge',
    title: 'Of Counsel',
    phone: '(310) 312-5401',
    fax: '(310) 312-5409',
    email: 'amonge@feldmanandassoc.com',
    practiceAreas: [
      'Business Law',
      'Construction Law',
      'Public Works',
      'Surety Law',
      'Civil Litigation',
      'Real Estate Litigation',
      'Prevailing Wage Litigation',
    ],
    education: [
      { title: 'University Attended', name: 'San Francisco State University, B.S., International Business, 2012' },
      { title: 'Law School Attended', name: 'University of Southern California, Los Angeles, CA, J.D., 2019' },
      { title: 'Year of First Admission', name: '2020' }
    ],
    admission: '2020, California'
  }

  // Add more attorneys as needed
];


function AttorneyPage() {
  // Get attorney ID from URL params using useParams hook
  let { id } = useParams();
  // Find the attorney in data based on ID
  const attorney = attorneysData.find(attorney => attorney.id === parseInt(id));

  if (!attorney) {
    return <div className="w-full m-10 text-center text-3xl">Attorney not found.</div>;
  }

  return (
    <div className="container mx-auto max-w-7xl">
      <div className="bg-accent m-6 grid gap-8 grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 px-10 py-6">
        <div className="relative overflow-hidden">
          {/* Attorney image - Replace with actual attorney image */}
          <img
            src={`/attorney-images/${attorney.id}.jpg`}
            alt={attorney.name}
            className="md:w-[300px] h-auto object-cover object-center"
          />
        </div>
        <div>
          <h1 className="text-4xl text-primary mb-6">{attorney.name}</h1>
          <p className="text-2xl font-semibold mb-4">{attorney.title}</p>
          {attorney.phone && <p><strong>Phone: </strong>{attorney.phone}</p>}
          {attorney.fax && <p><strong>Fax: </strong>{attorney.fax}</p>}
          {attorney.email && <p><a className="text-blue-700 underline" href={`mailto:${attorney.email}`}>{attorney.email}</a></p>}
        </div>
        {attorney.largeRatingBadge && (
          <div>
            <img src={attorney.largeRatingBadge} alt="Large Rating Badge" className="mt-4" />
          </div>
        )}
        {attorney.smallRatingBadge && (
          <div>
            <img src={attorney.smallRatingBadge.image} alt="Small Rating Badge" className="mt-4" />
            <p className="mt-6 mb-2"><strong>{attorney.smallRatingBadge.title}</strong><br />{attorney.smallRatingBadge.details}</p>
          </div>
        )}
      </div>
      <div className="m-6">
        {attorney.practiceAreas && attorney.practiceAreas.length > 0 && (
          <>
            <p className="mt-6 mb-2 text-2xl">Practice Areas:</p>
            <ul className="list-disc list-inside m-2">
              {attorney.practiceAreas.map((area, index) => (
                <li key={index}>{area}</li>
              ))}
            </ul>
          </>
        )}
        {attorney.education && attorney.education.length > 0 && (
          <>
            <p className="mt-6 mb-2 text-2xl">Education:</p>
            <div className="m-2">
              {attorney.education.map((edu, index) => (
                <div key={index}>
                  <p><strong>{edu.title}:</strong> {edu.name}</p>
                </div>
              ))}
            </div>
          </>
        )}
        {attorney.biography && (
          <>
            <p className="mt-6 mb-4 text-2xl">Biography:</p>
            <p className="m-2" style={{ whiteSpace: 'pre-line' }}>{attorney.biography}</p>
          </>
        )}
        {attorney.membershipsAndAwards && attorney.membershipsAndAwards.length > 0 && (
          <>
            <p className="mt-6 mb-4 text-2xl">Memberships and Awards:</p>
            <div className="m-2">
              {attorney.membershipsAndAwards.map((award, index) => (
                <div key={index}><p>{award}</p><br /></div>
              ))}
            </div>
          </>
        )}
        {attorney.reportedCases && attorney.reportedCases.length > 0 && (
          <>
            <p className="mb-4 text-2xl">Reported Cases:</p>
            <div className="m-2">
              {attorney.reportedCases.map((reportedCase, index) => (
                <div key={index}><p>{reportedCase}</p><br /></div>
              ))}
            </div>
          </>
        )}
        {attorney.admission && (
          <>
            <p className="mt-6 mb-4 text-2xl">Admission:</p>
            <div className="m-2">
              {attorney.admission.split(';').map((item, index) => (
                <p key={index}>{item.trim()}</p>
              ))}
            </div>
          </>
        )}
        {attorney.birthPlace && (
          <>
            <p className="mt-6 mb-4 text-2xl">Birth Place:</p>
            <p className="m-2">{attorney.birthPlace}</p>
          </>
        )}
      </div>
    </div>
  );
}

export default AttorneyPage;

