import React from 'react';

const Map = () => (
    <div className="container mx-auto py-5 px-5">
        <div className="flex flex-col md:flex-row">
            <div className="w-full md:w-1/4 p-4 mb-6">
                <h2 className="text-xl font-bold mb-2">Address</h2>
                <p>11030 Santa Monica Blvd #109</p>
                <p>Los Angeles, CA 90025</p>
                <h2 className="text-xl font-bold mb-2 mt-6">Phone</h2>
                <p>(310) 312-5401</p>
            </div>
            <div className="w-full lg:w-3/4">
                <iframe title="locationMap"
                    className="w-full h-[500px]" 
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3305.845108620138!2d-118.44477192379514!3d34.04784411796183!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80c2bb7798d11e3f%3A0x21c11129a8608b4!2s11030%20Santa%20Monica%20Blvd%20%23%20109%2C%20Los%20Angeles%2C%20CA%2090025!5e0!3m2!1sen!2sus!4v1720578479294!5m2!1sen!2sus" 
                    referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
            </div>
        </div>
    </div>
);

export default Map;
