import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";

function Navbar() {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const closeDropdown = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", closeDropdown);

    return () => {
      document.removeEventListener("mousedown", closeDropdown);
    };
  }, []);

  return (
    <div className="mx-auto navbar bg-base-100 max-w-7xl">
      <div className="flex-1 ml-5">
        <Link to="/" className="">
          <img src="logo.png" className="h-12" alt="Logo" />
        </Link>
      </div>
      <div className="flex-none mr-5 md:hidden">
        <div className="relative" ref={dropdownRef}>
          <button
            onClick={toggleDropdown}
            className="m-1 btn btn-ghost"
            aria-label="Toggle Menu"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              className="inline-block w-6 h-6 stroke-current" // Increased size here
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16M4 18h16"
              />
            </svg>
          </button>
          {dropdownOpen && (
            <div className="absolute right-0 z-10 p-2 mt-2 shadow bg-base-100 rounded-box w-52">
              <ul className="menu dropdown-content">
                <li>
                  <Link
                    to="/"
                    onClick={toggleDropdown}
                    className="text-gray-800 hover:bg-transparent"
                  >
                    HOME
                  </Link>
                </li>
                <li>
                  <Link
                    to="/about"
                    onClick={toggleDropdown}
                    className="text-gray-800 hover:bg-transparent"
                  >
                    ABOUT
                  </Link>
                </li>
                <li>
                  <Link
                    to="/practice-areas"
                    onClick={toggleDropdown}
                    className="text-gray-800 hover:bg-transparent"
                  >
                    PRACTICE AREAS
                  </Link>
                </li>
                <li>
                  <Link
                    to="/attorneys"
                    onClick={toggleDropdown}
                    className="text-gray-800 hover:bg-transparent"
                  >
                    ATTORNEYS
                  </Link>
                </li>
                <li>
                  <Link
                    to="/contact"
                    onClick={toggleDropdown}
                    className="text-gray-800 hover:bg-transparent"
                  >
                    CONTACT
                  </Link>
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>
      <div className="flex-none hidden mr-5 md:block">
        <ul className="px-1 font-sans menu menu-horizontal text-MD">
          <li>
            <Link
              to="/"
              className="relative inline-block text-gray-800 transition duration-150 ease-in-out before:absolute before:bottom-0 before:left-0 before:h-0.5 before:w-full before:scale-x-0 before:bg-blue-900 before:transition-transform before:duration-300 before:ease-in-out hover:before:scale-x-100 hover:bg-transparent"
            >
              HOME
            </Link>
          </li>
          <li>
            <Link
              to="/about"
              className="relative inline-block text-gray-800 transition duration-150 ease-in-out before:absolute before:bottom-0 before:left-0 before:h-0.5 before:w-full before:scale-x-0 before:bg-blue-900 before:transition-transform before:duration-300 before:ease-in-out hover:before:scale-x-100 hover:bg-transparent"
            >
              ABOUT
            </Link>
          </li>
          <li>
            <Link
              to="/practice-areas"
              className="relative inline-block text-gray-800 transition duration-150 ease-in-out before:absolute before:bottom-0 before:left-0 before:h-0.5 before:w-full before:scale-x-0 before:bg-blue-900 before:transition-transform before:duration-300 before:ease-in-out hover:before:scale-x-100 hover:bg-transparent"
            >
              PRACTICE AREAS
            </Link>
          </li>
          <li>
            <Link
              to="/attorneys"
              className="relative inline-block text-gray-800 transition duration-150 ease-in-out before:absolute before:bottom-0 before:left-0 before:h-0.5 before:w-full before:scale-x-0 before:bg-blue-900 before:transition-transform before:duration-300 before:ease-in-out hover:before:scale-x-100 hover:bg-transparent"
            >
              ATTORNEYS
            </Link>
          </li>
          <li>
            <Link
              to="/contact"
              className="relative inline-block text-gray-800 transition duration-150 ease-in-out before:absolute before:bottom-0 before:left-0 before:h-0.5 before:w-full before:scale-x-0 before:bg-blue-900 before:transition-transform before:duration-300 before:ease-in-out hover:before:scale-x-100 hover:bg-transparent"
            >
              CONTACT
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Navbar;
