import React from 'react';
import AttorneyCard from '../components/attorneyCard';

function Attorneys() {
    const attorneys = [
        { id: 1, name: 'Mark Feldman', title: 'Partner', bio: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla convallis libero ut libero placerat consequat.' },
        { id: 2, name: 'Adam Meyers', title: 'Partner', bio: 'Phasellus id mi eget justo fringilla lobortis. Cras eleifend sem vel odio bibendum dapibus.' },
        { id: 3, name: 'Sumner Schwartz', title: 'Associate', bio: 'Phasellus id mi eget justo fringilla lobortis. Cras eleifend sem vel odio bibendum dapibus.' },
        { id: 4, name: 'John Paul Cosico', title: 'Associate', bio: 'Phasellus id mi eget justo fringilla lobortis. Cras eleifend sem vel odio bibendum dapibus.' },
        { id: 5, name: 'Jessica P. Bravo', title: 'Of Counsel', bio: 'Phasellus id mi eget justo fringilla lobortis. Cras eleifend sem vel odio bibendum dapibus.' },
        { id: 6, name: 'Craig Lang', title: 'Of Counsel', bio: 'Phasellus id mi eget justo fringilla lobortis. Cras eleifend sem vel odio bibendum dapibus.' },
        { id: 7, name: 'Andrew Monge', title: 'Of Counsel', bio: 'Phasellus id mi eget justo fringilla lobortis. Cras eleifend sem vel odio bibendum dapibus.' },
    ];

    return (
        <div className="container mx-auto max-w-6xl text-center my-4">
            <h1 className="text-3xl font-bold mb-6 text-center">Attorneys</h1>
            <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-6">
                {attorneys.slice(0, 3).map(attorney => (
                    <div key={attorney.id} className="w-full">
                        <AttorneyCard attorney={attorney} />
                    </div>
                ))}
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-4 lg:grid-cols-4 gap-6 mt-6">
                {attorneys.slice(3).map(attorney => (
                    <div key={attorney.id} className="w-full">
                        <AttorneyCard attorney={attorney} />
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Attorneys;
