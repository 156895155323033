import React from 'react';
import Card from './card';

function CardContainer() {
  return (
    <div className="container mx-auto py-5 px-5">
      <div className="flex flex-wrap lg:grid lg:grid-cols-3 gap-4">
        <Card 
          title="Construction" 
          content="We understand builders and their construction partners. Our firm advises and assists clients at every stage of private and public construction projects. From bid disputes and contracting, to trial and beyond, we represent general contractors, subcontractors, material suppliers, private owners, and design professionals, in all phases of their construction work." 
          buttonText="Read More" 
          icon="construction"
        />
        <Card 
          title="Business and Real Estate" 
          content="We draft and negotiate a range of commercial agreements. Agreements include, but are not limited to, letters of intent, consulting agreements, lease agreements, confidentiality agreements, and other general commercial documents. We also represent property owners, purchasers, tenants, investors, lenders, and developers in a variety of real estate transactions. These include clients who are acquiring, selling, financing, leasing, building, or renovating real estate projects." 
          buttonText="Read More" 
          icon="building"
        />
        <Card 
          title="Prevailing Wage" 
          content="We represent both contractors and subcontractors on issues relating to prevailing wage compliance and hearings with the Department of Industrial Relations. We counsel clients on an array of prevailing wage issues, labor compliance, and reporting requirements for public works and private construction projects." 
          buttonText="Read More" 
          icon="money"
        />
      </div>
    </div>
  );
}

export default CardContainer;
