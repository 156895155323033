import React from 'react';
import { Link } from 'react-router-dom';

function AttorneyCard({ attorney }) {
    return (
        <Link to={`/attorneys/${attorney.id}`} className="block max-w-[240px] mx-auto my-5 hover:scale-105 transition-transform duration-300">
            <div className="bg-white rounded-lg shadow-md hover:shadow-xl overflow-hidden">
                <div className="relative overflow-hidden">
                    <img
                        src={`attorney-images/${attorney.id}.jpg`}
                        alt={attorney.name}
                        className="w-full h-auto object-cover object-center max-w-[240px] max-h-[240px]"
                        style={{ aspectRatio: '1:1' }}
                    />
                </div>
                <div className="p-4 text-center bg-accent">
                    <h2 className="text-xl font-semibold">{attorney.name}</h2>
                    <p className="text-gray-600">{attorney.title}</p>
                </div>
            </div>
        </Link>
    );
}

export default AttorneyCard;
