import React from 'react';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <footer className="footer bg-base-200 text-base-content p-10 mb-0 pb-0">
      <div className="w-full max-w-7xl mx-auto flex justify-between items-start relative">
        <aside>
          <Link to="/" className="">
            <img src="logo.png" className="h-12" alt="Footer Logo" />
          </Link>
          <p>
            Copyright © 2000-{new Date().getFullYear()} Feldman & Associates, Inc. <br /> All rights reserved.
          </p>
        </aside>
        <nav className="hidden md:flex md:flex-col md:gap-1 md:mx-12">
          <Link to="/about" className="link link-hover">About</Link>
          <Link to="/practice-areas" className="link link-hover">Practice Areas</Link>
          <Link to="/attorneys" className="link link-hover">Attorneys</Link>
          <Link to="/contact" className="link link-hover">Contact Us</Link>
        </nav>
      </div>
    </footer>
  );
}

export default Footer;
