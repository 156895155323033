import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './index.css';

// Import components and pages
import Navbar from './components/navbar';
import Hero from './components/hero';
import CardContainer from './components/cardContainer';
import Map from './components/map';
import Footer from './components/footer';
import About from './pages/about';
import PracticeAreas from './pages/practiceAreas';
import Attorneys from './pages/attorneys';
import Attorney from './pages/attorney';
import Contact from './pages/contact';
import AnimatedLink from './components/animatedLink';


// Import ScrollToTop component
import ScrollToTop from './components/scrollToTop';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <Router>
      <ScrollToTop /> {/* Place ScrollToTop component inside Router */}
      <Navbar />
      <Routes>
        <Route path="/" element={
          <>
            <Hero />
            <CardContainer />
            <Map />
          </>
        } />
        <Route path="/about" element={<About />} />
        <Route path="/practice-areas" element={<PracticeAreas />} />
        <Route path="/attorneys" element={<Attorneys />} />
        <Route path="/attorneys/:id" element={<Attorney />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
      <Footer />
      <AnimatedLink href="https://harout.io" text="WEB DEVELOPMENT BY HAROUT.IO" />
    </Router>
  </React.StrictMode>
);
