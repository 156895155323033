import React, { useState, useEffect } from "react";
import { Cursor } from "react-simple-typewriter";


const AnimatedLink = ({ href, text, handleLinkClick }) => {
  const letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  const [animationTimeoutId, setAnimationTimeoutId] = useState();
  const [displayText, setDisplayText] = useState(text);

  useEffect(() => {
    return () => {
      if (animationTimeoutId) {
        window.clearTimeout(animationTimeoutId);
      }
    };
  }, [animationTimeoutId]);

  const animate = (iteration) => {
    setDisplayText((prevText) => {
      return prevText
        .split("")
        .map((letter, index) => {
          if (index < iteration) {
            return text[index];
          }
          return letters[Math.floor(Math.random() * 26)];
        })
        .join("");
    });

    if (iteration < text.length) {
      const timeoutId = window.setTimeout(() => animate(iteration + 1 / 2), 15);
      setAnimationTimeoutId(timeoutId);
    }
  };

  const handleMouseEnter = () => {
    if (animationTimeoutId) {
      window.clearTimeout(animationTimeoutId);
    }
    animate(0);
  };

  const handleMouseLeave = () => {
    if (animationTimeoutId) {
      window.clearTimeout(animationTimeoutId);
    }
    setDisplayText(text);
  };

  const handleClick = (e) => {
    e.preventDefault();
    if (handleLinkClick) {
      handleLinkClick(href);
    }
    window.open(href, "_blank", "noopener,noreferrer");
  };



  return (
    <div className="bottom-0 flex justify-center p-2 bg-gray-200">
      <a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        className="animate-pulse hover:animate-none underline hover:no-underline text-xs font-thin text-black hover:bg-gray-800 hover:rounded-sm hover:text-green-500 hover:scale-110 p-1 transition duration-150 ease-in-out"
        style={{ fontFamily: 'Fira Code' }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={handleClick}
      >
        {displayText}
        <Cursor cursorStyle="_"></Cursor>
      </a>
    </div>
  );
};

export default AnimatedLink;
