import React from 'react';

import { Link } from 'react-router-dom';

function Hero() {
  return (
    <div className="relative h-[45rem] md:h-[35rem]">
      <img src="bg.jpg" alt="Descriptive Alt Text" className="w-full h-full object-cover" />
      <div className="absolute inset-0 bg-black opacity-45"></div>
      <div className="absolute inset-0 flex flex-col items-center justify-center">
        <div className="max-w-6xl mx-auto p-10 text-center text-white">
          <p className="text-2xl">
            Established in 2000, Feldman & Associates, Inc. is an AV rated, boutique law firm practicing in construction, prevailing wage, business, and real estate disputes. We serve the Greater Los Angeles area and counties from San Diego to San Francisco.
          </p>
          <p className="mt-4 text-2xl">
            Each client and case is unique. We work with clients to understand their goals and create a custom-tailored plan for cost-effective solutions.
          </p>
          <Link to="/about" className="mt-20 btn btn-accent hover:scale-105 transition-transform duration-100">Learn More</Link>
        </div>
      </div>
    </div>
  );
}

export default Hero;
