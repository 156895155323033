import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHelmetSafety, faMoneyBillTrendUp, faBuilding } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

function Card({ title, content, buttonText, icon }) {
  let selectedIcon = faHelmetSafety;

  if (icon === 'construction') {
    selectedIcon = faHelmetSafety;
  } else if (icon === 'money') {
    selectedIcon = faMoneyBillTrendUp;
  } else if (icon === 'building') {
    selectedIcon = faBuilding;
  }

  return (
    <div className="card bg-primary text-white shadow-xl mx-auto flex flex-col w-full">
      <div className="card-body flex flex-col justify-between h-full">
        <div className="flex items-center">
          <FontAwesomeIcon icon={selectedIcon} className="text-3xl" />
          <h2 className="ml-6 text-xl font-bold">{title}</h2>
        </div>
        <p className="mt-2">{content}</p>
        <div className="card-actions mt-auto">
          <Link to="/practice-areas" className="btn btn-neutral hover:scale-105 transition-transform duration-100">{buttonText}</Link>
        </div>
      </div>
    </div>
  );
}

export default Card;
