import React, { useState } from 'react';

function Contact() {
    // State to manage form inputs and errors
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        company: '',
        subject: '',
        message: ''
    });
    const [formErrors, setFormErrors] = useState({
        name: '',
        email: '',
        phone: '',
        subject: '',
        message: ''
    });

    // Update form input values
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
        // Clear related error when user starts typing again
        setFormErrors({ ...formErrors, [name]: '' });
    };

    // Form validation function
    const validateForm = () => {
        let isValid = true;
        const errors = {};

        // Basic validation rules
        if (!formData.name.trim()) {
            errors.name = 'Name is required';
            isValid = false;
        }
        if (!formData.email.trim()) {
            errors.email = 'Email is required';
            isValid = false;
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            errors.email = 'Email is invalid';
            isValid = false;
        }
        if (!formData.phone.trim()) {
            errors.phone = 'Phone number is required';
            isValid = false;
        }
        if (!formData.subject.trim()) {
            errors.subject = 'Subject is required';
            isValid = false;
        }
        if (!formData.message.trim()) {
            errors.message = 'Message is required';
            isValid = false;
        }

        // Update form errors state
        setFormErrors(errors);

        return isValid;
    };

    // Handle form submission
    const handleSubmit = (e) => {
        e.preventDefault();

        // Validate form before submission
        if (validateForm()) {
            // Example: Send email or perform other actions
            console.log('Form submitted with data:', formData);
            // Clear form fields after submission (optional)
            setFormData({
                name: '',
                email: '',
                phone: '',
                company: '',
                subject: '',
                message: ''
            });
            // Optionally, show a success message or redirect to a thank you page
        } else {
            console.log('Form validation failed. Please check errors.');
        }
    };

    return (
        <div className="mx-auto py-5 px-5 max-w-7xl">
            <div className="flex flex-col md:flex-row">
                <div className="mb-6 w-[275px]">
                    <h2 className="text-3xl mb-2">Location</h2>
                    <p className="text-sm">
                        11030 Santa Monica Blvd #109<br />
                        Los Angeles, CA 90025<br />
                        (310) 312-5401
                    </p>
                </div>
                <div className="w-full md:ml-10 md:w-3/4 mx-auto">
                    <iframe
                        title="locationMap"
                        className="w-full h-[400px]"
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3305.845108620138!2d-118.44477192379514!3d34.04784411796183!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80c2bb7798d11e3f%3A0x21c11129a8608b4!2s11030%20Santa%20Monica%20Blvd%20%23%20109%2C%20Los%20Angeles%2C%20CA%2090025!5e0!3m2!1sen!2sus!4v1720578479294!5m2!1sen!2sus"
                        referrerPolicy="no-referrer-when-downgrade"
                    ></iframe>
                </div>
            </div>
            <div className="divider divider-primary mt-10 text-2xl">Contact Us</div>
            <div id="contact-form" className="mt-8">
                <form onSubmit={handleSubmit} className="space-y-4">
                    <div className="flex flex-wrap -mx-2">
                        <div className="w-full md:w-1/2 px-2 mb-4">
                            <label htmlFor="name" className="block text-sm font-medium text-gray-700">Your Name</label>
                            <input
                                type="text"
                                id="name"
                                name="name"
                                value={formData.name}
                                onChange={handleInputChange}
                                required
                                className={`input input-bordered w-full ${formErrors.name && 'input-error'}`}
                            />
                            {formErrors.name && <p className="text-red-500 text-xs mt-1">{formErrors.name}</p>}
                        </div>
                        <div className="w-full md:w-1/2 px-2 mb-4">
                            <label htmlFor="email" className="block text-sm font-medium text-gray-700">Your Email</label>
                            <input
                                type="email"
                                id="email"
                                name="email"
                                value={formData.email}
                                onChange={handleInputChange}
                                required
                                className={`input input-bordered w-full ${formErrors.email && 'input-error'}`}
                            />
                            {formErrors.email && <p className="text-red-500 text-xs mt-1">{formErrors.email}</p>}
                        </div>
                        <div className="w-full md:w-1/2 px-2 mb-4">
                            <label htmlFor="phone" className="block text-sm font-medium text-gray-700">Your Phone Number</label>
                            <input
                                type="tel"
                                id="phone"
                                name="phone"
                                value={formData.phone}
                                onChange={handleInputChange}
                                required
                                className={`input input-bordered w-full ${formErrors.phone && 'input-error'}`}
                            />
                            {formErrors.phone && <p className="text-red-500 text-xs mt-1">{formErrors.phone}</p>}
                        </div>
                        <div className="w-full md:w-1/2 px-2 mb-4">
                            <label htmlFor="company" className="block text-sm font-medium text-gray-700">Your Company</label>
                            <input
                                type="text"
                                id="company"
                                name="company"
                                value={formData.company}
                                onChange={handleInputChange}
                                className="input input-bordered w-full"
                            />
                        </div>
                        <div className="w-full px-2 mb-4">
                            <label htmlFor="subject" className="block text-sm font-medium text-gray-700">Subject</label>
                            <input
                                type="text"
                                id="subject"
                                name="subject"
                                value={formData.subject}
                                onChange={handleInputChange}
                                required
                                className={`input input-bordered w-full ${formErrors.subject && 'input-error'}`}
                            />
                            {formErrors.subject && <p className="text-red-500 text-xs mt-1">{formErrors.subject}</p>}
                        </div>
                        <div className="w-full px-2 mb-4">
                            <label htmlFor="message" className="block text-sm font-medium text-gray-700">Your Message</label>
                            <textarea
                                id="message"
                                name="message"
                                value={formData.message}
                                onChange={handleInputChange}
                                rows="4"
                                required
                                className={`textarea textarea-bordered w-full ${formErrors.message && 'input-error'}`}
                            ></textarea>
                            {formErrors.message && <p className="text-red-500 text-xs mt-1">{formErrors.message}</p>}
                        </div>
                    </div>
                    <button
                        type="submit"
                        className="btn btn-primary hover:scale-105 hover:shadow-md transition-transform duration-100"
                    >
                        Submit
                    </button>
                </form>
            </div>
        </div>
    );
}

export default Contact;
